// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-tsx": () => import("/opt/build/repo/src/pages/contact_us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-livestock-fish-js": () => import("/opt/build/repo/src/pages/livestock_fish.js" /* webpackChunkName: "component---src-pages-livestock-fish-js" */),
  "component---src-pages-nuts-fruit-js": () => import("/opt/build/repo/src/pages/nuts_fruit.js" /* webpackChunkName: "component---src-pages-nuts-fruit-js" */),
  "component---src-pages-skin-leather-js": () => import("/opt/build/repo/src/pages/skin_leather.js" /* webpackChunkName: "component---src-pages-skin-leather-js" */),
  "component---src-pages-success-tsx": () => import("/opt/build/repo/src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-wool-js": () => import("/opt/build/repo/src/pages/wool.js" /* webpackChunkName: "component---src-pages-wool-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

