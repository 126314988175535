import React from 'react';

const logoMobile = require('../images/logo-mobile.png');
const logo = require('../images/logo.png');
import Button from '../components/button';
import LanguagesMenu from '../components/languagesMenu';
const styles = require('./header.module.scss');
import ContextConsumer from './context';
import { Link } from 'gatsby';
import BurgerMenu from './burgerMenu';
let language;
interface Props {}

interface State {
  showMenu: boolean;
  window: Number;
}

export default class Header extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      window: null,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.renderLinks = this.renderLinks.bind(this);
  }

  componentDidMount() {
    this.setState({
      window: window.innerWidth,
    });
  }
  renderLinks = () => {
    return this.state.window < 1185 ? (
      <BurgerMenu language={language} />
    ) : (
      <div className={styles.topBarButtons}>
        <Button
          otherClasses={styles.topBarButtonsButton}
          buttonColor="#26333c"
          buttonSize="mid"
          isButton={false}
          buttonUrl="/skin_leather"
        >
          {language === 'EN' ? 'Hides & Skins' : 'Pellame'}
        </Button>
        <Button
          otherClasses={styles.topBarButtonsButton}
          buttonColor="#26333c"
          buttonSize="mid"
          isButton={false}
          buttonUrl="/livestock_fish"
        >
          {language === 'EN'
            ? 'OVINE & FISH MEALS'
            : 'FARINE DI AGNELLO & PESCE'}
        </Button>
        <Button
          otherClasses={styles.topBarButtonsButton}
          buttonColor="#26333c"
          buttonSize="mid"
          isButton={false}
          buttonUrl="/wool"
        >
          {language === 'EN' ? 'Wool & Cashmere' : 'Lana & Cashmere'}
        </Button>
        <Button
          otherClasses={styles.topBarButtonsButton}
          buttonColor="#26333c"
          buttonSize="mid"
          isButton={false}
          buttonUrl="/nuts_fruit"
        >
          {language === 'EN' ? 'Nuts & Dried Fruits' : 'Frutta Secca'}
        </Button>
        <Button
          otherClasses={styles.topBarButtonsButton}
          buttonColor="#26333c"
          buttonSize="mid"
          isButton={false}
          buttonUrl="/contact_us"
        >
          {language === 'EN' ? 'Contact Us' : 'Contatti'}
        </Button>
      </div>
    );
  };
  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  closeMenu() {
    this.setState({ showMenu: false });
  }

  render() {
    return (
      <div>
        <ContextConsumer>
          {({ data }) => {
            language = data.contextLanguage;
            return (
              <div className={styles.topBar}>
                <div className={styles.topBarContainer}>
                  <div className={styles.topBarRow}>
                    <Link to="/" title="bocciardo.com">
                      <img className={styles.logo} src={logo} alt="bocciardo" />
                    </Link>
                    <div className={styles.topBarRightSide}>
                      {this.renderLinks()}
                      <LanguagesMenu
                        toggleMenu={this.toggleMenu}
                        open={true}
                        // open={this.state.showMenu}
                        language={language}
                        closeMenu={this.closeMenu}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </ContextConsumer>
      </div>
    );
  }
}
