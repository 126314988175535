import React from 'react';
import './languagesMenu.scss';
const portugal = require('../images/portugal.svg');
const italy = require('../images/italian_flag.svg');

const unitedKingdom = require('../images/united-kingdom.svg');
import ContextConsumer from './context';
interface Props {
  toggleMenu: Function;
  open: boolean;
  children?: any;
  language: string;
  closeMenu: Function;
}

interface State {}

export default class LanguagesMenu extends React.Component<Props, State> {
  wrapperRef: any;

  constructor(props: Props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleToogleMenu = this.handleToogleMenu.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleBodyClick = this.handleBodyClick.bind(this);
  }

  handleToogleMenu() {
    // event.preventDefault();
    this.props.toggleMenu();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleBodyClick);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleBodyClick(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeMenu();
    }
  }

  render() {
    return (
      <div
        onClick={this.handleToogleMenu}
        ref={this.setWrapperRef}
        className="toggle-menu"
      >
        {/* <div className="topBar-languagesMenu">
          <div className="chooseLanguage">
            {this.props.language == 'EN' ? (
              <div>
                <img
                  className="flag"
                  src={unitedKingdom}
                  alt="united kingdom"
                  title="EN"
                />
              </div>
            ) : (
              <div>
                <img className="flag" src={italy} alt="italy" title="IT" />
              </div>
            )}
          </div>
        </div> */}

        {this.props.open ? (
          <div className="languagesMenu-container">
            <div className="languagesMenu">
              <ContextConsumer>
                {({ data, set }) => (
                  <div
                    className="languageOption"
                    onClick={() => set({ contextLanguage: 'EN' })}
                  >
                    <img
                      className="flag"
                      src={unitedKingdom}
                      alt="united kingdom"
                    />
                    {/* <div className="languageName">English</div> */}
                  </div>
                )}
              </ContextConsumer>
              <ContextConsumer>
                {({ data, set }) => (
                  <div
                    className="languageOption"
                    onClick={() => set({ contextLanguage: 'IT' })}
                  >
                    <img className="flag" src={italy} alt="portugal" />
                    {/* <div className="languageName">Italiano</div> */}
                  </div>
                )}
              </ContextConsumer>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
