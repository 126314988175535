import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './burgerMenu.scss';
import { Link } from 'gatsby';

class BurgerMenu extends React.Component {
  state = {
    menuOpen: false,
  };
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  render() {
    return (
      <Menu
        {...this.props}
        right
        isOpen={this.state.menuOpen}
        onStateChange={state => this.handleStateChange(state)}
      >
        <Link to="/skin_leather" onClick={() => this.closeMenu()}>
          {this.props.language === 'EN' ? 'HIDES & SKINS' : 'PELLAME'}
        </Link>
        <Link to="/livestock_fish" onClick={() => this.closeMenu()}>
          {this.props.language === 'EN'
            ? 'OVINE & FISH MEALS'
            : 'FARINE DI AGNELLO & PESCE'}
        </Link>
        <Link to="/wool" onClick={() => this.closeMenu()}>
          {this.props.language === 'EN' ? 'WOOL & CASMERE' : 'LANA & CASHMERE'}
        </Link>
        <Link to="/nuts_fruit" onClick={() => this.closeMenu()}>
          {this.props.language === 'EN' ? 'NUTS & DRIED FRUIT' : 'FRUTTA SECCA'}
        </Link>
        <Link to="/contact_us" onClick={() => this.closeMenu()}>
          {this.props.language === 'EN' ? 'CONTACT US' : 'CONTATTI'}
        </Link>
      </Menu>
    );
  }
}

export default BurgerMenu;
