/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import 'typeface-roboto';
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import './layout.scss';
import { ContextProviderComponent } from './context';
import Header from '../components/header';
import NewFooter from '../components/newFooter';

const Layout = ({ children }) => (
  <>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className="wrapper">
          <div className="content">
            <ContextProviderComponent>
              <Header />
              <main>{children}</main>
              <NewFooter />
            </ContextProviderComponent>
          </div>
        </div>
      )}
    />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
