import React from 'react';
import { Link } from 'gatsby';
const styles = require('./footer.module.scss');
const logo = require('../images/logo.png');
const linkedin = require('../images/linkedin.png');
class NewFooter extends React.Component {
  render() {
    return (
      <div className={styles.footer}>
        <div className={styles.infoContainer}>
          <Link to="/" title="bocciardo">
            <img src={logo} alt="logo" className={styles.footerLogo} />
          </Link>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              listStyle: 'none',
            }}
          >
            <p style={{ fontSize: '17px' }}>
              Follow us on <strong>Linked</strong>
            </p>
            {/* <ul className={styles.socialFooter}> */}
            <li className={styles.socialItem}>
              <a
                className={styles.socialLink}
                href="https://www.linkedin.com/company/bocciardo-brokers-srl/about/"
                target="_blank"
                rel="noopener noreferrer"
                title="Linkedin"
              >
                {/* <img src={linkedin} className={styles.linkedin} /> */}
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  data-icon="social"
                  width="20"
                  height="20"
                  viewBox="0 0 128 128"
                  className={styles.iconicFill}
                >
                  <g data-width="18" data-height="18" transform="scale(8)">
                    <g>
                      <path d="M4.745,13.634L2.371,13.634L2.371,5.999L4.745,5.999L4.745,13.634ZM3.558,4.955C2.797,4.955 2.182,4.338 2.182,3.579C2.182,2.82 2.797,2.203 3.558,2.203C4.317,2.203 4.933,2.82 4.933,3.579C4.933,4.339 4.316,4.955 3.558,4.955ZM13.633,13.634L11.263,13.634L11.263,9.921C11.263,9.035 11.246,7.896 10.029,7.896C8.794,7.896 8.606,8.861 8.606,9.857L8.606,13.634L6.234,13.634L6.234,5.999L8.511,5.999L8.511,7.042L8.543,7.042C8.86,6.442 9.634,5.809 10.789,5.809C13.19,5.809 13.634,7.39 13.634,9.447L13.634,13.635L13.633,13.634Z" />
                    </g>
                  </g>
                </svg>
              </a>
            </li>
            {/* </ul> */}
          </div>
          <small className={styles.copyright}>
            {' '}
            &copy; Bocciardo Brokers s.r.l. | All rights reserved. | P.I.
            02552430999
          </small>
        </div>
      </div>
    );
  }
}

export default NewFooter;
