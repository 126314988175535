// Buttons!
//
import './utils/a-button.scss';
import { Link } from 'gatsby';

import * as React from 'react';

interface Props {
  buttonColor?: string;
  buttonSize?: string;
  buttonType?: string;
  buttonUrl?: string;
  onClick?: (event: any) => any;
  otherClasses?: string;
  otherId?: string;
  children?: any;
  isButton?: boolean;
  dataAttribute?: string;
  dataRemote?: boolean;
  targetBlank?: boolean;
  disabled?: boolean;
  extraStyle?: React.CSSProperties;
}

export default class Button extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  classNameConstructor(props) {
    let classy = 'lw-button';

    if (props.buttonColor) {
      classy += ` lw-button--${props.buttonColor}`;
    } else {
      classy += ` lw-button--puertoRico`;
    }

    if (props.buttonSize) {
      classy += ` lw-button--${props.buttonSize}`;
    } else {
      classy += ` lw-button--mid`;
    }

    if (props.buttonType) {
      classy += ` lw-button--${props.buttonType}`;
    }

    if (props.otherClasses) {
      classy += ' ' + props.otherClasses;
    }

    return classy;
  }

  render() {
    if (this.props.isButton) {
      return (
        <button
          style={this.props.extraStyle}
          className={this.classNameConstructor(this.props)}
          onClick={this.props.onClick}
          id={this.props.otherId}
          data-attribute={this.props.dataAttribute}
          data-remote={this.props.dataRemote}
          disabled={this.props.disabled}
        >
          {this.props.children}
        </button>
      );
    } else {
      return (
        <Link
          style={this.props.extraStyle}
          to={this.props.buttonUrl}
          className={this.classNameConstructor(this.props)}
          id={this.props.otherId}
          data-attribute={this.props.dataAttribute}
          data-remote={this.props.dataRemote}
          onClick={this.props.onClick}
          target={this.props.targetBlank ? '_blank' : '_self'}
        >
          {this.props.children}
        </Link>
      );
    }
  }
}
